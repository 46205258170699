import React, { useState, useEffect } from 'react'

import { isMobile, getLocalStorage } from './src/utils/utils'

export const AppContext = React.createContext()

function Provider ({ children }) {
    const [ isMobileDevice, setIsMobileDevice ] = useState()

    useEffect(() => {
        setIsMobileDevice(isMobile())
        const {blackTheme = false, zoom = '1'} = getLocalStorage('acessibility')
        const filter = blackTheme ? 'grayscale(100%)' : ''
        document.documentElement.setAttribute('style', `filter: ${filter}; zoom:${zoom};`)
    }, [isMobileDevice])

    return (
      <AppContext.Provider value={{isMobileDevice}}>
          {children}
      </AppContext.Provider>
    )
}

export default ({ element }) => (
  <Provider>
    {element}
  </Provider>
)
/**
 * Checks if current browser has a mobile agent.
 *
 * @returns {boolean} True if mobile, false if don't.
 */
export function isMobile() {

    const userAgents = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ]

    if (typeof window !== undefined) {
        return Boolean(userAgents.find(userAgent => window.navigator.userAgent.match(userAgent)))
    }
}

/**
 * Allow only one call within the time limit
 * @param { Function } func function to limited
 * @param { Number } limit time limite in miliseconds
 */
export function throttle(func, limit) {
    let inThrottle
    return function () {
        const args = arguments
        const context = this
        if (!inThrottle) {
            func.apply(context, args)
            inThrottle = true
            setTimeout(() => {
                inThrottle = false
            }, limit)
        }
    }
}

export function setLocalStorage(attribute, payload) {
    const existingPayload = getLocalStorage(attribute)
    const _payload = { ...existingPayload, ...payload }
    localStorage.setItem(attribute, JSON.stringify(_payload))
}

export function getLocalStorage(attribute) {
    let storageItem
    try {
        storageItem = JSON.parse(localStorage.getItem(attribute))
    } catch (error) {
        console.log('cannot parse accessibility from storage')
        storageItem = {}
    }

    return storageItem || {}
}
